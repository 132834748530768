import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Primary button" component="button" variation="button--primary" hasReactVersion hasVueVersion="button--primary" hasAngularVersion="?path=/story/button--basic" codepen="xeZxLe" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Secondary button" component="button" variation="button--secondary" hasReactVersion hasVueVersion="button--secondary" hasAngularVersion="?path=/story/button--basic&knob-Button kind=secondary&knob-Size of the buttons=normal" codepen="OGMJxW" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Tertiary button" component="button" variation="button--tertiary" hasReactVersion hasVueVersion="button--tertiary" hasAngularVersion="?path=/story/button--basic&knob-Button kind=tertiary&knob-Size of the buttons=normal" codepen="VNewrL" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Ghost button" component="button" variation="button--ghost" hasReactVersion hasVueVersion="button--ghost" hasAngularVersion="?path=/story/button--basic&knob-Button kind=ghost&knob-Size of the buttons=normal" codepen="MRKWZz" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Danger button" component="button" variation="button--danger" hasReactVersion hasVueVersion="button--danger" hasAngularVersion="?path=/story/button--basic&knob-Button kind=danger&knob-Size of the buttons=normal" codepen="wZMvNP" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small primary button" component="button" variation="button--primary--small" hasReactVersion hasVueVersion="button--primary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=primary&knob-Size of the buttons=sm" codepen="oObNVb" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small secondary button" component="button" variation="button--secondary--small" hasReactVersion hasVueVersion="button--secondary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=secondary&knob-Size of the buttons=sm" codepen="ROrNbp" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small tertiary button" component="button" variation="button--tertiary--small" hasReactVersion hasVueVersion="button--tertiary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=tertiary&knob-Size of the buttons=sm" codepen="ZZQYGN" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small ghost button" component="button" variation="button--ghost--small" hasReactVersion hasVueVersion="button--ghost&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=ghost&knob-Size of the buttons=sm" codepen="eoJmpw" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Small danger button" component="button" variation="button--danger--small" hasReactVersion hasVueVersion="button--danger&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=danger&knob-Size of the buttons=sm" codepen="KYVwXY" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Button set" component="button" variation="button--set" hasReactVersion hasAngularVersion="?path=/story/button--basic" codepen="pBgvdX" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="button" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      